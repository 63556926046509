import { Grid, Typography, useMediaQuery } from "@mui/material";
import {
  doubtedBlue,
  doubtedOrange,
  normal,
  purple,
  secondaryText,
  text,
  yellow,
} from "../../utils/themeContstants";
import { getLootType } from "../../utils/helpers";

const RealisticRules = ({ token }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const getMapCode = () => {
    if (token?.game_match_type) {
      switch (token?.game_match_type) {
        case "zbm_real":
          if (token?.team_size > 2) {
            return "3229-8874-3593";
          } else {
            return "0362-1958-6769";
          }
        case "real":
          return token?.game_map_type === "finest" ? "9854-1829-8735" : "1467-4908-0291";
        case "zbm_bf":
          return "9631-7035-4812";
        default:
          return;
      }
    }

    if (token?.game_mode_options?.options?.length === 1) {
      switch (token?.game_mode_options?.options[0]) {
        case "zbm_real":
          if (token?.team_size > 2) {
            return "3229-8874-3593";
          } else {
            return "0362-1958-6769";
          }
        case "real":
          return token?.game_map_type === "finest" ? "9854-1829-8735" : "1467-4908-0291";
        case "zbm_bf":
          return "9631-7035-4812";
        default:
          return;
      }
    }
  };

  const getMapName = () => {
    if (token?.game_match_type) {
      switch (token?.game_match_type) {
        case "real":
          return token?.game_map_type === "finest" ? "Finest Realistic 2 (1v1-4v4)" : "RIVOX REALISTICS (1v1-4v4)";
        case "zbm_real":
          if (token?.team_size > 2) {
            return "(Versatile ZB Realistic 2.0 (3v3 TO 4v4))";
          } else {
            return "(Versatile ZB Realistic (1v1 TO 2v2))";
          }
        case "zbm_bf":
          return "(Versatile ZB BoxFight (1v1 TO 3v3))";
        default:
          return;
      }
    }

    if (token?.game_mode_options?.options?.length === 1) {
      switch (token?.game_mode_options?.options[0]) {
        case "real":
          return token?.game_map_type === "finest" ? "Finest Realistic 2 (1v1-4v4)" : "RIVOX REALISTICS (1v1-4v4)";
        case "zbm_real":
          if (token?.team_size > 2) {
            return "(Versatile ZB Realistic 2.0 (3v3 TO 4v4))";
          } else {
            return "(Versatile ZB Realistic (1v1 TO 2v2))";
          }
        case "zbm_bf":
          return "(Versatile ZB BoxFight (1v1 TO 3v3))";
        default:
          return;
      }
    }
  };

  const getMapMode = () => {
    if (token?.game_match_type) {
      switch (token?.game_match_type) {
        case "real":
          return getLootType(token?.extra_options?.loot_type);
        case "zbm_real":
          return "Default loot (unless agreed upon in chat";
        case "zbm_bf":
          return "Default loot";
        default:
          return;
      }
    }

    if (token?.game_mode_options?.options?.length === 1) {
      switch (token?.game_mode_options?.options[0]) {
        case "real":
          return getLootType(token?.extra_options?.loot_type);
        case "zbm_real":
          return "Default loot (unless agreed upon in chat";
        case "zbm_bf":
          return "Default loot";
        default:
          return;
      }
    }
  };

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="start"
      justifyContent="center"
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : "100%" }}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>MAP</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <span style={{ color: doubtedBlue }}>{getMapCode()}</span>{" "}
              <span style={{ color: text, fontWeight: 500 }}>
                {getMapName()}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>MODE</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>{getMapMode()}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>TEAMS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Host is{" "}
                <span style={{ color: doubtedBlue, fontWeight: 700 }}>
                  Team 1
                </span>
              </li>
              <li>
                Non-Host is{" "}
                <span style={{ color: doubtedOrange, fontWeight: 700 }}>
                  Team 2
                </span>
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>ROUND ELIGIBILITY</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                If loot has been given and any player leaves,{" "}
                <span style={{ fontWeight: 800 }}>continue the round</span>, do
                NOT leave the match.
              </li>

              <li>
                If you{" "}
                <span style={{ fontWeight: 800 }}>respawn mid-round</span>, you
                forfeit the round.
              </li>

              <li>
                If you are caught opening chests, ammo crates, or picking up floor loot, you will forfeit the round.
              </li>

            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RealisticRules;
