import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useMiddleware from "../../api/useMiddleware";
import { getUserProfile } from "../../api/user";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  getDateFromId,
  getReadableDateString,
  isWithinOneMinute,
  numFormatter,
} from "../../utils/helpers";
import {
  badgeIconSize,
  black,
  blue,
  cardDark,
  cardLight,
  large,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
  transition,
  yellow,
  purple,
  red,
  green,
  muffinsIdea,
  arcteryx,
} from "../../utils/themeContstants";
import Badge from "../custom/Badge";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import { TbShieldLock, TbSocialOff, TbSwords } from "react-icons/tb";
import { MdGames, MdPlace } from "react-icons/md";
import SocialBadge from "../leaderboard/SocialBadge";
import TipModal from "../tip/TipModal";
import { AccountTypeEnum } from "../../utils/enums";
import SecondaryButton from "../custom/SecondaryButton";
import PunishModal from "../staff/PunishModal";
import { useNavigate } from "react-router-dom";
import { FaCrown, FaShieldAlt } from "react-icons/fa";
import Avatar from "../../avatar/Avatar";
import AddNoteModal from "../staff/AddNoteModal";
import ResetStatsModal from "./ResetStatsModal";
import { ImLock, ImPower } from "react-icons/im";
import UserTipModal from "../tip/UserTipModal";
import { BsController, BsJoystick } from "react-icons/bs";
import { IoLogoGameControllerA } from "react-icons/io";
import { BiJoystick, BiJoystickAlt } from "react-icons/bi";
import { MdGamepad, MdOutlineGamepad } from "react-icons/md";
import ACCoin from "../custom/ACCoin";
import { CgUmbrella } from "react-icons/cg";
import { TiCloudStorage } from "react-icons/ti";
import { doubtedOrange } from "../../utils/themeContstants";
import { GiSwordsEmblem } from "react-icons/gi";
import UpgradeModal from "../app/UpgradeModal";
import ResetEarningsModal from "./ResetEarningsModal";

const PublicProfileModal = ({ open, onClose }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [searchedUser, setSearchedUser] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tipOpen, setTipOpen] = useState(false);
  const [punishOpen, setPunishOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetEarningsOpen, setResetEarningsOpen] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);

  const handleClose = () => {
    setSearchedUser(null);
    setLoading(true);
    setUser(null);
    setTipOpen(false);
    setPunishOpen(false);
    setNoteOpen(false);
    setResetOpen(false);
    setUpgradeOpen(false);
    onClose();
  };

  useEffect(() => {
    setSearchedUser(store?.searchUser);
  }, [store?.searchUser]);

  useEffect(() => {
    if (open && searchedUser && loading) {
      if (user == null) {
        getUserProfile(middleware, searchedUser).then((res) => {
          setLoading(false);
          if (res?.error) {
            updateStore({ type: SET_ERRORS, payload: res?.message });
            handleClose();
          } else {
            setUser(res);
          }
        });
      }
    }
  }, [open, middleware, searchedUser, user, loading, updateStore]);

  const handleNavigate = (match_id) => {
    handleClose();
    return navigate(`/token/${match_id}`);
  };

  const styles = {
    width: {
      width: "100%",
    },
    username: {
      fontSize: large,
      fontWeight: 800,
      color: user?.premium_info?.active ? "rgb(255, 255, 193)" : text,
      textShadow: user?.premium_info?.active
        ? "rgb(255 93 0) 0px 0px 10px"
        : null,
    },
    epic: {
      fontWeight: 500,
      color: secondaryText,
      fontSize: normal,
    },
    tipContainer: {
      borderRadius: 1,
      padding: 1,
      backgroundColor: "rgba(255, 229, 0, 0.13)",
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(255, 229, 0, 0.23)",
      },
    },
    stats: {
      padding: 2,
      width: "100%",
      borderRadius: 1,
      backgroundColor: cardLight,
    },
    label: {
      fontSize: 13,
      color: offWhite,
      fontWeight: 600,
    },
  };

  return (
    <>
      <UpgradeModal
        open={!store?.user?.premium_info?.active ? tipOpen : false}
        onClose={handleClose}
      />

      <UserTipModal
        open={store?.user?.premium_info?.active ? tipOpen : false}
        onClose={() => setTipOpen(false)}
        closeSearch={() => {}}
        user={
          user
            ? {
                user_id: user?._id,
                username: user?.username,
                avatar: user?.avatar,
                isPremium: user?.premium_info?.active,
                epic: user?.connections?.epic?.name,
              }
            : null
        }
      />

      <PunishModal
        open={punishOpen}
        onClose={() => setPunishOpen(false)}
        user={user}
      />
      <AddNoteModal
        open={noteOpen}
        onClose={() => setNoteOpen(false)}
        user={user}
      />
      <ResetStatsModal
        open={resetOpen}
        onClose={() => setResetOpen(false)}
        closeProfile={handleClose}
      />
      <ResetEarningsModal
        open={resetEarningsOpen}
        onClose={() => setResetEarningsOpen(false)}
      />
      <CustomModal
        loading={loading}
        open={open}
        onClose={handleClose}
        minWidth={600}
      >
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid container justifyContent="start" alignItems="center">
              <Grid item sx={{ marginLeft: -2, marginTop: -2 }}>
                <Avatar
                  size={250}
                  avatar={user?.new_avatar}
                  bgColor={cardDark}
                />
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.username}>
                                  {user?.username}
                                </Typography>
                              </Grid>

                              {(user?.connections?.twitch?.name ||
                                user?.connections?.twitter?.name ||
                                user?.connections?.discord?.name ||
                                user?.account_type >=
                                  AccountTypeEnum.MODERATOR) && (
                                <>
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent="start"
                                      alignItems="center"
                                      gap={{ xs: 1 }}
                                    >


                                  {(user?.account_type >=
                                        AccountTypeEnum.MODERATOR) && (
                                        <Badge label="STAFF">
                                          <FaShieldAlt
                                            style={{
                                              color: blue,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {/* {(user?.account_type ===
                                        AccountTypeEnum.MODERATOR ||
                                        user?.account_type ===
                                          AccountTypeEnum.SENIOR_MODERATOR) && (
                                        <Badge label="MODERATOR">
                                          <FaShieldAlt
                                            style={{
                                              color: blue,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.GOD && (
                                        <Badge label="OWNER">
                                          <ImPower
                                            style={{
                                              color: green,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.SENIOR_ADMIN && (
                                        <Badge label="SR. ADMIN">
                                          <GiSwordsEmblem
                                            style={{
                                              color: "white",
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.ADMIN && (
                                        <Badge label="ADMIN">
                                          <TbSwords
                                            style={{
                                              color: red,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.JUNIOR_ADMIN && (
                                        <Badge label="JR. ADMIN">
                                          <TbSwords
                                            style={{
                                              color: purple,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.ANTICHEAT && (
                                        <Badge label="ANTICHEAT STAFF">
                                          <TiCloudStorage
                                            style={{
                                              color: muffinsIdea,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )} */}

                                      {user?.connections?.twitter && (
                                        <SocialBadge
                                          type="twitter"
                                          style={{}}
                                          onClick={() =>
                                            window.open(
                                              `${user?.connections?.twitter?.external_link}`,
                                              "_blank"
                                            )
                                          }
                                        />
                                      )}
                                      {user?.connections?.discord && (
                                        <SocialBadge
                                          type="discord"
                                          style={{}}
                                          user={
                                            user?.connections?.discord?.name
                                          }
                                        />
                                      )}

                                      {user?.connections?.twitch && (
                                        <SocialBadge
                                          type="twitch"
                                          onClick={() =>
                                            window.open(
                                              `${user?.connections?.twitch?.external_link}`,
                                              "_blank"
                                            )
                                          }
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* {user?.match_info?.match_id && (
                                <Badge
                                  label="In Match"
                                  onClick={() =>
                                    handleNavigate(user?.match_info?.match_id)
                                  }
                                >
                                  <MdOutlineGamepad
                                    style={{
                                      color: text,
                                      fontSize: badgeIconSize,
                                    }}
                                  />
                                </Badge>
                              )} */}
                              {user?.anticheat?.must_run_ac &&
                                isWithinOneMinute(
                                  user?.anticheat?.last_heartbeat_timestamp
                                ) && (
                                  <Badge label="Running Rivox AC">
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <ACCoin size={16} />
                                    </Grid>
                                  </Badge>
                                )}
                              {user?.stats?.rank > 0 && (
                                <Badge label={"Top " + user?.stats?.rank}>
                                  <MdGames
                                    style={{
                                      color: arcteryx,
                                      fontSize: badgeIconSize,
                                    }}
                                  />
                                </Badge>
                              )}
                              {user?.premium_info?.active && (
                                <Badge label="VIP">
                                  <FaCrown
                                    style={{
                                      color: yellow,
                                      fontSize: badgeIconSize,
                                    }}
                                  />
                                </Badge>
                              )}

                              {!user?.connections?.twitch?.name &&
                                !user?.connections?.twitter?.name &&
                                !user?.connections?.discord?.name && (
                                  <Badge label="No Socials Linked">
                                    <TbSocialOff
                                      style={{
                                        color: secondaryText,
                                        fontSize: badgeIconSize,
                                      }}
                                    />
                                  </Badge>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {store?.user?.account_type >=
                        AccountTypeEnum.ADMIN && (
                        <Grid item>
                          <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                            gap={{ xs: 1 }}
                          >
                            {store?.user?._id !== user?._id && (
                              <>
                                <Grid item>
                                  <SecondaryButton
                                    label="Give Karma"
                                    size="small"
                                    onClick={() => setPunishOpen(true)}
                                  />
                                </Grid>

                                {/* <Grid item>
                                  <SecondaryButton
                                    label="Add Note"
                                    size="small"
                                    onClick={() => setNoteOpen(true)}
                                  />
                                </Grid> */}
                              </>
                            )}

                            {store?.user?.account_type >=
                              AccountTypeEnum.JUNIOR_ADMIN && (
                              <Grid item>
                                <SecondaryButton
                                  label="View"
                                  size="small"
                                  onClick={() => {
                                    navigate("/staff/search", {
                                      state: {
                                        user_id: user?._id,
                                      },
                                    });
                                    handleClose();
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {user?._id !== store?.user?._id && (
                    <Grid
                      item
                      sx={styles.tipContainer}
                      onClick={() => {
                        if (!store?.user) {
                          navigate("/login");
                        } else {
                          setTipOpen(true);
                        }
                      }}
                    >
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 0.5 }}
                      >
                        <RivoxCoin size={20} />

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: small,
                              fontWeight: 800,
                              color: yellow,
                            }}
                          >
                            TIP
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.stats}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.label}>
                  Joined On:{" "}
                  <span style={{ fontWeight: 800, color: text }}>
                    {getReadableDateString(getDateFromId(user?._id))}
                  </span>
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography sx={styles.label}>Total Earned:</Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 0.5 }}
                    >
                      <RivoxCoin size={15} />

                      <Grid item>
                        <Typography
                          sx={{ ...styles.label, fontWeight: 800, color: text }}
                        >
                          {numFormatter().format(user?.stats?.earnings)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Typography sx={styles.label}>
                  Total Wins:{" "}
                  <span style={{ fontWeight: 800, color: text }}>
                    {user?.stats?.wins}
                  </span>
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.label}>
                  Total Losses:{" "}
                  <span style={{ fontWeight: 800, color: text }}>
                    {user?.stats?.losses}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {store?.user?._id === user?._id && (
            <Grid container direction="row" alignItems="center">
              <Grid item style={{ paddingRight: "8px" }}>
                <SecondaryButton
                  label="Reset Stats"
                  size="small"
                  onClick={() => setResetOpen(true)}
                />
              </Grid>
              <Grid item>
                <SecondaryButton
                  label="Reset Earnings"
                  size="small"
                  onClick={() => setResetEarningsOpen(true)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </CustomModal>
    </>
  );
};

export default PublicProfileModal;
