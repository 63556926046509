import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MdAccountBalanceWallet } from "react-icons/md";
import { StoreContext } from "../../store/Store";
import SpacingWrapper from "../../utils/SpacingWrapper";
import { cardVeryLight, text } from "../../utils/themeContstants";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomMenuButton from "../custom/CustomMenuButton";
import SecondaryButton from "../custom/SecondaryButton";
import ScrollableContainer from "../custom/ScrollableContainer";
import { CDN_URL } from "../../utils/constants";

const Shop = () => {

  const storeIcon = `${CDN_URL}3d_icons/store.png`;
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isTablet = useMediaQuery("(max-width: 768px)");

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const path = location?.pathname;

    if (path?.split("/")[2] === "avatars") {
      return setSelected("avatars");
    }

    if (path?.split("/")[2] === "shop-item") {
      return setSelected("avatars");
    }

    if (path?.split("/")[2] === "crates") {
      return setSelected("crates");
    }

    if (path?.split("/")[2] === "coins") {
      return setSelected("coins");
    }

    if (path?.split("/")[2] === "other") {
      return setSelected("other");
    }
    if (path?.split("/")[2] === "bp") {
      return setSelected("bp");
    }

    setSelected(null);
  }, [location?.pathname]);

  const styles = {
    width: {
      width: "100%",
    },
    vipWithdrawals: {
      color: "rgb(255, 255, 193)",
      textShadow: "rgb(255 93 0) 0px 0px 10px",
      fontSize: 14,
      fontWeight: 500,
      opacity: 0.8,
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 700,
    },
  };

  return (
    <>
      <Grid item sx={{ width: "100%", minHeight: "100vh", paddingBottom: 8 }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <SpacingWrapper>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="start"
              gap={{ xs: 2 }}
            >
              <>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    gap={{ xs: 2 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={storeIcon}
                            draggable={false}
                            height={42}
                            width={42}
                          />
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.header}>Shop</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {store?.user && !isTablet && (
                      <Grid item>
                        <SecondaryButton
                          bg="rgba(32, 108, 84, 1)"
                          label="Add Funds"
                          onClick={() => navigate("/shop/coins")}
                          icon={
                            <MdAccountBalanceWallet
                              style={{ fontSize: 18, color: text }}
                            />
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "100%",
                    borderBottom: `1px solid ${cardVeryLight}`,
                  }}
                >
                  {!isTablet && (
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <CustomMenuButton
                        label="Avatars"
                        onClick={() => navigate("/shop/avatars")}
                        selected={selected === "avatars"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Crates"
                        onClick={() => navigate("/shop/crates")}
                        selected={selected === "crates"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Coins"
                        onClick={() => navigate("/shop/coins")}
                        selected={selected === "coins"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Rivox Pass"
                        onClick={() => navigate("/shop/bp")}
                        selected={selected === "bp"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Other"
                        onClick={() => navigate("/shop/other")}
                        selected={selected === "other"}
                        size={isTablet ? null : "large"}
                      />
              
                    </Grid>
                  )}

                  {isTablet && (
                    <ScrollableContainer gap={1} paddingBottom={0}>
                      <CustomMenuButton
                        label="Avatars"
                        onClick={() => navigate("/shop/avatars")}
                        selected={selected === "avatars"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Crates"
                        onClick={() => navigate("/shop/crates")}
                        selected={selected === "crates"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Coins"
                        onClick={() => navigate("/shop/coins")}
                        selected={selected === "coins"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Other"
                        onClick={() => navigate("/shop/other")}
                        selected={selected === "other"}
                        size={isTablet ? null : "large"}
                      />
                      <CustomMenuButton
                        label="Rivox Pass"
                        onClick={() => navigate("/shop/bp")}
                        selected={selected === "other"}
                        size={isTablet ? null : "large"}
                      />
                    </ScrollableContainer>
                  )}
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 2 }}
                    sx={{ paddingTop: 1 }}
                  >
                    <Outlet />
                  </Grid>
                </Grid>
                {/* <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>World Cup Pack</Typography>
                    </Grid>

                    <Grid item sx={{ flexGrow: 1 }}>
                      <Divider
                        sx={{
                          backgroundColor: cardVeryLight,
                          borderRadius: 1,
                          height: 2,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MdOutlineTimer style={{ color: text, fontSize: 26 }} />
                    </Grid>

                    {loading && (
                      <Grid item>
                        <Skeleton
                          variant="text"
                          animation="pulse"
                          sx={{
                            bgcolor: cardVeryLight,
                            height: 30,
                            width: 150,
                          }}
                        />
                      </Grid>
                    )}

                    {!loading && (
                      <Grid item>
                        <Typography sx={styles.time} noWrap>
                          <Countdown
                            date={new Date(shop?.featured_reset_timestamp)}
                            renderer={renderer}
                          />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid> */}

                {/* <Grid item alignSelf="end">
                  <Typography sx={styles.meta}>
                    The Launch Exclusives will <b>NEVER</b> return to the shop
                    once they leave.
                  </Typography>
                </Grid> */}

                {/* <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  alignSelf="center"
                >
                  <Typography sx={styles.vipWithdrawals}>
                    <FaCrown style={{ color: secondaryText, fontSize: 12 }} />{" "}
                    When you purchase VIP, you will be given two random pieces
                    from the RARE to LEGENDARY rarities.
                  </Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 2 }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <Typography sx={styles.header}>
                            Avatar Crates
                          </Typography>
                        </Grid>

                        <Grid item sx={{ flexGrow: 1 }}>
                          <Divider
                            sx={{
                              backgroundColor: cardVeryLight,
                              borderRadius: 1,
                              height: 2,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill,minmax(194px, 6fr))",
                          gap: 12,
                        }}
                      >
                        <CrateItem
                          type={AvatarRarityEnum.EXOTIC}
                          onClick={() => navigate("/crates/exotic")}
                        />
                        <CrateItem
                          type={AvatarRarityEnum.RARE}
                          onClick={() => navigate("/crates/rare")}
                        />
                        <CrateItem
                          type={AvatarRarityEnum.GODLIKE}
                          onClick={() => navigate("/crates/godlike")}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 2 }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <Typography sx={styles.header}>
                            Emoji Avatars
                          </Typography>
                        </Grid>

                        <Grid item sx={{ flexGrow: 1 }}>
                          <Divider
                            sx={{
                              backgroundColor: cardVeryLight,
                              borderRadius: 1,
                              height: 2,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill,minmax(194px, 6fr))",
                          gap: 12,
                        }}
                      >
                        {loading && (
                          <>
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                            <StoreItemGlimmer />
                          </>
                        )}

                        {!loading && shop && (
                          <>
                            {shop?.featured_items?.map((item, i) => (
                              <ShopItem
                                key={i}
                                item={item}
                                onClick={() =>
                                  navigate("/shop-item", {
                                    state: {
                                      item,
                                    },
                                  })
                                }
                              />
                            ))}
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>Current Shop</Typography>
                    </Grid>

                    <Grid item sx={{ flexGrow: 1 }}>
                      <Divider
                        sx={{
                          backgroundColor: cardVeryLight,
                          borderRadius: 1,
                          height: 2,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MdOutlineTimer style={{ color: text, fontSize: 26 }} />
                    </Grid>

                    {loading && (
                      <Grid item>
                        <Skeleton
                          variant="text"
                          animation="pulse"
                          sx={{
                            bgcolor: cardVeryLight,
                            height: 30,
                            width: 150,
                          }}
                        />
                      </Grid>
                    )}

                    {!loading && (
                      <Grid item>
                        <Typography sx={styles.time} noWrap>
                          <Countdown
                            date={new Date(shop?.reset_timestamp)}
                            renderer={renderer}
                          />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill,minmax(194px, 6fr))",
                      gap: 12,
                    }}
                  >
                    {loading && (
                      <>
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                        <StoreItemGlimmer numItems={1} />
                      </>
                    )}

                    {!loading && shop && (
                      <>
                        {shop?.daily_items?.map((item, i) => (
                          <ShopItem
                            key={i}
                            item={item}
                            onClick={() =>
                              navigate("/shop-item", {
                                state: {
                                  item,
                                },
                              })
                            }
                          />
                        ))}
                      </>
                    )}
                  </div>
                </Grid>

                <Grid item alignSelf="center" sx={{ marginTop: 4 }}>
                  <Typography sx={styles.meta}>
                    These items are purely cosmetic and grant no competitive
                    advantage.
                  </Typography>
                </Grid>
               */}
              </>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default Shop;
