import { Grid, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { getPurchaseTitle } from "../../utils/helpers";
import {
  cardVeryLight,
  normal,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import Piece from "../../avatar/Piece";
import { useContext, useEffect, useState } from "react";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { getPieceValue } from "../../api/avatar";
import { CDN_URL } from "../../utils/constants";

const PurchaseListItem = ({
  purchase,
  onClick,
  backgroundHover = cardVeryLight,
  minWidth,
}) => {

  const crate = `${CDN_URL}crate.svg`;
  const battle_pass = `${CDN_URL}bp-icon.svg`;
  const premium = `${CDN_URL}3d_icons/premium.png`;
  const unban = `${CDN_URL}3d_icons/unban.png`;
  const chatUnban = `${CDN_URL}3d_icons/chat-unban.png`;
  const snipes = `${CDN_URL}3d_icons/snipes.png`;
  const reset = `${CDN_URL}3d_icons/reset.png`;
  const earnings = `${CDN_URL}3d_icons/earnings-reset.png`;
  const unlink = `${CDN_URL}3d_icons/unlink.png`;




  const isTablet = useMediaQuery("(max-width: 768px)");
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  const [valueLoading, setValueLoading] = useState(false);
  const [avatarValue, setAvatarValue] = useState(null);

  const handleGetValue = () => {
    setValueLoading(true);
    getPieceValue(purchase?.piece_id).then((res) => {
      setValueLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setAvatarValue(res?.piece_value);
      }
    });
  };

  const getPurchaseImage = () => {
    switch (purchase?.type) {
      case "crate":
      case "rare_items_crate":
      case "emoji_crate":
      case "emote_crate":
        return crate;
      case "VIP":
        return premium;
      case "UNBAN":
        return unban;
      case "CHAT_UNBAN":
        return chatUnban;
      case "STATS":
        return reset;
      case "EARNINGS_RESET":
        return earnings;
      case "SNIPES":
        return snipes;
      case "UNLINK":
        return unlink;
      case "BP":
        return battle_pass;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (purchase && !purchase?.type) {
      handleGetValue();
    }
  }, [purchase]);

  const styles = {
    container: {
      borderRadius: 2,
      padding: 1,
      maxWidth: "100%",
      minWidth: isTablet ? "100%" : minWidth ? minWidth : null,
      width: "100%",
      minHeight: 52,
      boxSizing: "border-box",
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: backgroundHover,
      },
    },
    title: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
    description: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 400,
      maxWidth: isTablet ? "100%" : 280,
    },
  };

  return (
    <Grid item sx={styles.container} onClick={onClick}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1.5 }}
        wrap="nowrap"
      >
        {/* <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Grid> */}
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {purchase?.type && (
            <img
              src={getPurchaseImage()}
              draggable={false}
              width={36}
              alt="purchase-image"
            />
          )}

          {!purchase?.type && valueLoading && (
            <Grid item>
              <Skeleton
                variant="circular"
                width={36}
                height={36}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation="pulse"
              />
            </Grid>
          )}

          {!purchase?.type && !valueLoading && avatarValue && (
            <Grid item sx={{ margin: -1, marginRight: -0.5 }}>
              <Piece
                type={purchase?.category}
                color={
                  store?.user?.new_avatar?.options[`${purchase?.category}Color`]
                }
                option={avatarValue}
                size={50}
                skinColor={store?.user?.new_avatar?.options?.skinColor}
              />
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.title}>
                {getPurchaseTitle(purchase)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PurchaseListItem;
