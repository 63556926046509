import React, { useState } from "react";
import { Grid, Typography, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomModal from "../custom/CustomModal"; // Ensure this path is correct
import CustomMenuButton from "../custom/CustomMenuButton";
import ClaimWalletMenu from "../wallet_menu/ClaimWalletMenu";

const IframeModal = ({ open, onClose, title, iframeUrl, height = "900px" }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selected, setSelected] = useState("claim");


    const handleIframeLoad = () => {
        setLoading(false);
    };

    const handleIframeError = () => {
        setLoading(false);
        setError(true);
    };

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={title}
            titleSize="large"
        // Add any additional props if needed
        >
            {/* <CustomMenuButton
                label="Claim"
                onClick={() => setSelected("claim")}
                selected={selected === "claim"}
                stretch={true}
            /> */}
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item sx={{ width: "100%", position: "relative" }}>
                    <IconButton
                        onClick={onClose}
                        sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {loading && (
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: height,
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                                zIndex: 2,
                            }}
                        >
                            <CircularProgress />
                            <Typography variant="body1" sx={{ marginTop: 2 }}>
                                Loading Checkout...
                            </Typography>
                        </Grid>
                    )}
                    {error ? (
                        <Typography color="error">Failed to load the checkout. Please try again.</Typography>
                    ) : (
                        <iframe
                            src={iframeUrl}
                            title={title}
                            width="100%"
                            height={height}
                            style={{ border: "none", borderRadius: "8px" }}
                            onLoad={handleIframeLoad}
                            onError={handleIframeError}
                        ></iframe>
                    )}
                    {selected === "claim" && <ClaimWalletMenu />}
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default IframeModal;
