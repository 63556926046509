import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineStop } from "react-icons/ai";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { text } from "../../utils/themeContstants";
import CustomMenuButton from "../custom/CustomMenuButton";

const StaffFSDashboard = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(max-width: 768px)");
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState("users");

  useEffect(() => {
    setSelected(location?.pathname?.split("/")[3]);
  }, [location]);

  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <AiOutlineStop style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>FORCE STREAM DASHBOARD</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <CustomMenuButton
            label="Users"
            onClick={() => navigate("/staff/fs/users")}
            selected={selected === "users"}
            size={isTablet ? null : "large"}
          />
        </Grid>
      </Grid>

      <Outlet />
    </>
  );
};

export default StaffFSDashboard;
