import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CDN_URL, CLIENT_URL } from "../../utils/constants";
import {
  getNotiTimestamp,
  getReadableDateString,
  getTime,
  numFormatter,
} from "../../utils/helpers";
import {
  black,
  card,
  cardDark,
  cardLight,
  green,
  modalShadow,
  normal,
  red,
  secondaryButton,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";

const ExpenseItem = ({ expense }) => {


  const paypal = `${CDN_URL}paypal-wallet.svg`;
  const cashapp = `${CDN_URL}cash-app-wallet.svg`;
  const bluevine = `${CDN_URL}blue-vine-expenses.svg`;
  const venmo = `${CDN_URL}venmo-expenses.svg`;



  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);

  const getAmount = () => {
    {
      return (
        <Grid item>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 0.5 }}
          >
            <RivoxCoin size={18} />
            <Grid item>
              <Typography sx={{ ...styles.value, color: red }}>
                - {numFormatter().format(expense?.amount)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  // const getPaymentMethod = () => {
  //   switch (expense?.payment_method) {
  //     case "bank":
  //       // setPaymentImage("bank");
  //       return "Bluevine";
  //     case "cashapp":
  //       // setPaymentImage("cashapp");
  //       return "Cash App";
  //     case "paypal":
  //       // setPaymentImage("paypal");
  //       return "PayPal";
  //     case "venmo":
  //       // setPaymentImage("venmo");
  //       return "Venmo";
  //   }
  // };
  const getPaymentMethodThumbnail = () => {
    switch (expense?.payment_method) {
      case "bank":
        return bluevine;
      case "cashapp":
        return cashapp;
      case "paypal":
        return paypal;
      case "venmo":
        return venmo;
    }
  };

  const getPaymentMethodImage = () => {
    return (
      <Grid item>
        {/* <Typography sx={styles.value}>
          {getPaymentMethod(expense?.payment_method)}
        </Typography> */}
        <img
          draggable={false}
          alt="payment-logo"
          src={getPaymentMethodThumbnail(expense?.payment_method)}
          style={{ width: 75 }}
        />
      </Grid>
    );
  };

  const getTypeColor = () => {
    if (expense?.type === "rivoxpoints") {
      return secondaryButton;
    }

    switch (expense?.type) {
      case "expense":
        return red;
      case "canceled":
      case "payout":
        return green;
      case "user_pool_payout":
        return yellow;
    }
  };

  const styles = {
    container: {
      padding: 2,
      borderRadius: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      width: "100%",
      transition: transition,
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    expenseValue: {
      fontSize: 12,
      color: black,
      fontWeight: 800,
      textTransform: "uppercase",
    },
    resultText: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 800,
      textTransform: "uppercase",
    },
    status: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 2,
      backgroundColor: getTypeColor(),
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="row"
        alignItems="start"
        justifyContent="space-between"
        sx={{
          width: "100%",
          padding: 1,
          borderRadius: 2,
          backgroundColor: cardLight,
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          sx={{
            width: "100%",
            paddingBottom: 1,
            borderRadius: 2,
            backgroundColor: cardLight,
          }}
        >
          <Grid item>
            <Typography sx={styles.label}>
              {getReadableDateString(new Date(expense?.timestamp)) +
                " at " +
                getTime(new Date(expense?.timestamp))}
              , by: {expense?.username}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.label}>AMOUNT</Typography>
            </Grid>

            {getAmount()}
          </Grid>
        </Grid>

        {isDesktop && (
          <>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>Type</Typography>
                </Grid>

                <Grid item sx={styles.status}>
                  <Typography sx={styles.expenseValue}>
                    {expense?.type}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {expense && (
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>PAYMENT METHOD</Typography>
                  </Grid>

                  {/* <Grid item>
                    <Typography sx={styles.value}>
                      {getPaymentMethod(expense?.payment_method)}
                    </Typography>
                    <img
                      draggable={false}
                      alt="paypal-logo"
                      src={getPaymentMethodImage()}
                      style={{ width: 50 }}
                    />
                  </Grid> */}
                  {getPaymentMethodImage()}
                </Grid>
              </Grid>
            )}
          </>
        )}
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={{ ...styles.label, textAlign: "right" }}>
                PAID TO
              </Typography>
            </Grid>
            <Grid item sx={styles.resultText}>
              <Typography sx={styles.value}>
                {expense?.paid_to?.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={{ ...styles.label, textAlign: "right" }}>
                FOR
              </Typography>
            </Grid>
            <Grid item sx={styles.resultText}>
              <Typography sx={styles.value}>
                {expense?.description?.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item alignSelf="end">
              <Typography sx={{ ...styles.label, textAlign: "right" }}>
                DATE
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                sx={{ ...styles.value, fontWeight: 500, color: secondaryText }}
              >
                {getReadableDateString(new Date(expense?.timestamp)) +
                  " at " +
                  getTime(new Date(expense?.timestamp))}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default ExpenseItem;
