import { Grid } from "@mui/material";
import { CDN_URL } from "../../utils/constants";

const ACCoin = ({ size }) => {


  const AC = `${CDN_URL}AC_COIN.svg`;
  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img draggable={false} height={size} width={size} src={AC} />
    </Grid>
  );
};

export default ACCoin;
